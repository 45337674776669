import React, { FC, memo } from "react";
import { Link } from "react-router-dom";
import {BiMenu} from 'react-icons/bi'

const Navlist: FC = memo(() => {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
        <nav className="lg:w-full mr-5">
        <button
        className="text-white ml-auto mt-5 cursor-pointer text-xl px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
        type="button"
        onClick={() => setNavbarOpen(!navbarOpen)}>
        <BiMenu/>
        </button>
        <div className={" lg:block text-white" + (navbarOpen ? " flex" : " hidden")} >
            <ul className=" space-y-6 py-4 sm:space-y-0 sm:py-0 flex flex-col lg:flex-row text-xs xl:text-lg justify-evenly text-white font-Bagero  ">
                <li className="p-0 sm:p-6 hover:bg-opacity-20 hover:bg-white">
                <Link to="/" >
                    Home
                </Link>
                </li>
                <li className="p-0 sm:p-6 hover:bg-opacity-20 hover:bg-white">
                <a href="#games" >
                    Our Made Games
                </a>
                </li>
                <li className="p-0 sm:p-6 hover:bg-opacity-20 hover:bg-white">
                <a href="#Services">
                    Services
                </a>
                </li>
                <li className="p-0 sm:p-6 hover:bg-opacity-20 hover:bg-white">
                <Link to="/">
                    Career
                </Link>
                </li>
                <li className="p-0 sm:p-6 hover:bg-opacity-20 hover:bg-white">
                <a href="#contact">
                    Contact
                </a>
                </li>
                <li className="p-0 sm:p-6 hover:bg-opacity-20 hover:bg-white">
                <a href="#customerSuport">
                    Customer Suport
                </a>
                </li>
            </ul> 
        </div> 
        </nav>
        
    </>
  );
});

export default Navlist;
