import { memo } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/Logo 1.png";
import Navlist from "./utilities/Navlist";
const Navbar = memo(({defaultP}:{defaultP?:boolean}) => {
  return (
    <nav className={`bg-[#25465B] flex lg:items-center justify-between  w-full z-50 top-0 left-0 ${defaultP?'fixed':"sticky"}`}>
        <>
            <div className="flex justify-center basis-40 sm:basis-48 md:basis-64">
                <Link to="/">
                    <img src={logo} alt="logo" className=" h-16 w-20 my-2"/>
                </Link> 
            </div>
            <Navlist/>
        </>
   
    </nav>
  );
});

export default Navbar;
