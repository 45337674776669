import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import { Routes, Route } from "react-router-dom";
import ClientInfo from "./components/ClientInfo";
import PublicRoute from "./components/utilities/PublicRoute";
import PrivateRoute from "./components/utilities/PrivateRoute";
import Privacy from "./pages/Privacy";
import Contact from "./pages/Contact";
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/customerSuport" element={<Contact/>} />
      <Route path="/contact" element={<Contact/>} />
      <Route
        path="/SignIn"
        element={
          <PublicRoute>
            <SignIn />
          </PublicRoute>
        }
      />
      <Route
        path="/client"
        element={
          <PrivateRoute>
            <ClientInfo />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <div className="bg-[#45ACB1] h-screen">
            <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
              <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                <div className="max-w-md text-center">
                  <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
                    <span className="sr-only">Error</span>404
                  </h2>
                  <p className="text-2xl font-semibold md:text-3xl">
                    Sorry, we couldn't find this page.
                  </p>
                  <p className="mt-4 mb-8 dark:text-gray-400">
                    But dont worry, you can find plenty of other things on our
                    homepage.
                  </p>
                  <a
                    rel="noopener noreferrer"
                    href="/"
                    className="px-8 py-3 font-bold rounded bg-red-600 hover:bg-green-600 text-lg"
                  >
                    Back to homepage
                  </a>
                </div>
              </div>
            </section>
          </div>
        }
      />
    </Routes>
  );
};
export default App;
