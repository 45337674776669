import React, { memo, FC } from "react";

const ColabR: FC<{ img?: string; alt?: string }> = memo(({ img, alt }) => {
  return (
    <div className="p-10 flex h-60 md:h-44 items-center justify-center">
      <img src={img} alt={alt} className="md:w-full w-1/2 " />
    </div>
  );
});

export default ColabR;
