import React, { memo } from 'react';
import { Link } from "react-router-dom";
import logo from "../../assets/images/Logo 1.png";
const FooterL = memo(() => {
    return (
        <div className=' basis-1/2 md:basis-[40%] lg:basis-[30%] text-white'>
            <div className='pb-5 flex items-center justify-start'>
                <Link to="/">
                    <img src={logo} alt="logo" className=" h-16 w-20"/>
                </Link>
                <h2 className='px-4 text-3xl font-medium'>Game Bite</h2> 
            </div>
            <div className=" text-xl font-normal text-white opacity-80">
                <Link to="/">
                    <h2 className='pb-2'>Home</h2>
                </Link>
                <a href="#games"> 
                    <h2 className='py-2'>Our Made Games</h2>
                </a>
                <a href="#Services">
                    <h2 className='py-2'>Services</h2>
                </a>
                <Link to="/">
                    <h2 className='py-2'>Career</h2>
                </Link>
                <a href="#contact">
                    <h2 className='py-2'>Contact </h2>
                </a>
                <Link to="/">
                    <h2 className='pt-2'>Custmor Support </h2>
                </Link>
            </div>
        </div>
    );
});

export default FooterL;