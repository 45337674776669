import React, { FC,memo } from "react";
import playstore from "../../assets/images/google play.png";
import appstore from "../../assets/images/app store.png";
import { GamesProps } from "../../Datatypes/main";
const GamesCard:FC<{data:GamesProps}> = memo(({data}) => {
  return (
    <div>
      <div className="flex items-center w-[340px] lg:w-[470px] h-44 ">
        <div className=" basis-[30%]">
          <img src={data?.img} alt={data?.alt} className="w-56 lg:w-64"/>
        </div>
        <div className=" px-4 basis-[70%]">
          <p className=" text-2xl lg:text-4xl font-medium text-[#0068C2]">{data?.text}</p>
          <div className="flex pt-3 space-x-3">
            <a href={data?.playstore}><img src={playstore} alt="" className=" w-8 md:w-6 text-white"/></a>
            <a href={data?.appstore}><img src={appstore} alt="" className=" w-8 md:w-6 text-white"/></a>
          </div>
        </div>
      </div>
    </div>
  );
});

export default GamesCard;
