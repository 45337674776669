import React, { memo, FC } from "react";

const ServiceBox: FC<{ data: any }> = memo(({ data }) => {
  return (
    <div className="flex flex-col lg:flex-row my-5 xl:my-36 ">
      <div className={`relative ${data?.direction ? "order-1" : "order-2"}`}>
        <div className="hidden xl:block  h-60 w-60">
          <div
            className={`flex items-center h-[120%] w-[120%] absolute rotate-45 bg-[#328BD9] ${
              data.direction ? "top-[-6%] left-[25%]" : "top-[-6%] left-[-45%]"
            }`}
          >
            <div className="font-Bagero text-5xl text-center font-semibold text-white rotate-[-45deg]">
              {data?.title}
            </div>
          </div>
        </div>
      </div>
      {/* Xl Screen */}
      <div className={`hidden xl:block ${data?.direction ? "order-2" : "order-1"}`}>
        <div className={`w-full xl:w-[982px] xl:min-h-[239px] bg-[#2976A6] flex flex-col md:flex-row py-4 ${data?.direction ? "justify-end" : "justify-start"}`}>
          <p className={`basis-1/2 text-justify text-base font-normal text-white px-3 pt-1 ${data?.direction ? "order-1" : "order-2"}`}>{data?.desc}</p>
          <div className={`basis-1/4 flex justify-center ${data?.direction ? "order-2" : "order-1"}`}><img src={data?.img} alt="" className=" object-contain" /></div>
        </div>
      </div>
      {/* lg screen */}
      <div className={`hidden lg:block xl:hidden ${data?.direction ? "order-3" : "order-1"}`}>
        <div className={`w-full xl:w-[982px] xl:h-[239px] bg-[#2976A6] flex flex-col md:flex-row py-4 ${data?.direction ? "justify-end" : "justify-start"}`}>
          <div className={` ${data?.direction ? "order-1" : "order-3"} flex items-center justify-center bg-[#328BD9] rotate-45 h-40 w-40 mx-auto my-auto `}>
             <h1 className="font-Bagero -rotate-45 text-2xl font-semibold text-white text-center">{data?.title}</h1>
          </div>
          <p className={`basis-1/2 text-base text-justify font-normal text-white px-3 pt-5 ${data?.direction ? "order-1" : "order-2"}`}>{data?.desc}</p>
          <div className={`basis-1/4  flex justify-center ${data?.direction ? "order-2" : "order-1"}`}><img src={data?.img} alt="" /></div>
        </div>
      </div>
      {/* Sm Screen */}
      <div className={`lg:hidden `}>
        <div className={`w-full bg-[#2976A6] p-2 `}>
          <h1 className="font-Bagero md:text-left text-center text-xl py-2 font-semibold text-white">{data?.title}</h1>
          <div className=" flex flex-col md:flex-row justify-between">
            <p className={`basis-3/4 text-base text-justify font-normal text-white`}>{data?.desc}</p>
            <div className={`basis-1/4 py-4 flex justify-center`}><img src={data?.img} alt="" /></div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ServiceBox;
