import { memo } from "react";
import Container from "./utilities/Container";
const Header = memo(() => {
  return (
    <div className="bg-[url('../images/image.jpg')] bg-no-repeat bg-cover min-h-screen flex items-center text-center ">
        <Container className=" mb-10">
                <>
                    <div className="text-white">
                        <h1 className=" text-2xl sm:text-3xl lg:text-5xl font-Bagero">Let’s develop your next outstanding mobile game jointly</h1>
                        <p className=" text-lg sm:text-xl font-normal py-10 md:py-20">GameBite is a mobile app development studio focused on mobile games creation. Our company is experienced in game design, software engineering, Unity 3D engine, and 2D/3D art creation for mobile games. Let’s create outstanding mobile games jointly</p>
                        <button className="font-Bagero text-xl font-normal bg-transparent hover:bg-opacity-20 hover:bg-white py-2 px-4 border border-white hover:border-transparent rounded h-16 w-64 ">
                            <a href="#contact">Create a Game</a>
                        </button>
                    </div>
                </>
    
        </Container>
    </div>
  );
});

export default Header;
