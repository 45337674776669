import actions from "./Actions";
const { GET_Client_DATA, GET_Companies_DATA, GET_Info_DATA, SAVE_IN_INFO,SIGN_IN_INFO } =
  actions;

const reducer = (state: any, actions: { payload: any; type: string }) => {
  switch (actions.type) {
    case GET_Client_DATA:
      return { ...state, clientData: actions.payload };
    case GET_Companies_DATA:
      return { ...state, CompaniesData: actions.payload };
    case GET_Info_DATA:
      return { ...state, InfoData: actions.payload };
    case SAVE_IN_INFO:
      return { ...state, SaveInfo: actions.payload };
    case SIGN_IN_INFO:
      return { ...state, userInfo: actions.payload };
    default:
      return state;
  }
};
export default reducer;
