import React, { memo } from 'react';
import Client from '../components/Client';
import Colaborated from '../components/Colaborated';
import CreateGame from '../components/CreateGame';
import Footer from '../components/Footer';
import FormSection from '../components/FormSection';
import Header from '../components/Header';
import LetsMake from '../components/LetsMake';
import Navbar from '../components/Navbar';
// import Provide from '../components/Provide';
import Services from '../components/Services';
import WhyCompanies from '../components/WhyCompanies';
import MadeGames from '../components/MadeGames';
const Home = memo(() => {
    return (
       <>
       <div className='bg-[#ccdcea] bg-[url("../images/bgm.png")] bg-no-repeat bg-cover'>
            {/* <div className='bg-[url("../images/Smoke.png")] bg-no-repeat bg-cover'> */}
                <Navbar/>
                <Header/>
                <CreateGame/>
                <LetsMake/>
                <Colaborated/>
                <WhyCompanies/>
                {/* <Provide/> */}
                <MadeGames/>
                <Services/>
                <FormSection/>
                <Client/>
                <Footer/>
            </div> 
        {/* </div> */}
       </>
    );
});

export default Home;