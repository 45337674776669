import React, { memo } from 'react';
import Container from './utilities/Container';
import MakeCard from './utilities/MakeCard';
import mainData from '../data/maindata.json'
import { whyChoseProps } from '../Datatypes/main';
const LetsMake = memo(() => {
    const ChoseData:whyChoseProps[]=mainData.whyChoseUs
    return (
        <div className=' my-10'>
            <Container>
                <>
                <h1 className='font-Bagero text-[#274659] text-center md:text-left text-2xl sm:text-4xl md:text-5xl font-semibold py-6'>Lets Make Game</h1>
                <div className="flex flex-col justify-center text-center md:text-left md:flex-row md:flex-wrap lg:justify-evenly ">
                    {
                    ChoseData.map((obj:whyChoseProps,i)=>(
                        <MakeCard key={obj.id} data={obj} indexVal={i}/>
                    ))
                    }
                </div>
                <div className=' pt-8 text-center'>
                    <button className="font-Bagero hover:bg-opacity-20 hover:bg-white text-xl font-medium text-[#2976A6] bg-transparent border-4 border-[#2976A6] rounded py-3 px-10">
                        <a href='#contact'>Lets Start</a>
                    </button>
                </div>
                </>
            </Container>
        </div>
        
    );
});

export default LetsMake;