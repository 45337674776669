import React, { memo } from 'react';
const FooterR = memo(() => {
    return (
        <div className='order-2 sm:order-3 md:order-2 basis-[60%] md:basis-2/5 text-white pt-2'>
            <h1 className=' font-Bagero my-3 pt-1 text-lg font-normal opacity-80'>Services we provide</h1> 
            <div className=' text-xl font-normal opacity-60 space-y-7'>
                <p className='pt-4'>Hyper-casual games development</p>
                <p className=''>NFT games development</p>
                <p className=''>Branded games development</p>
                <p className=''>Idle games development</p>
                <p className=''>AR & VR development</p>
            </div>
        </div>
    );
});

export default FooterR;