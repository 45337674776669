import React, { memo } from "react";
import ColabL from "./utilities/ColabL";
import ColabR from "./utilities/ColabR";
import Container from "./utilities/Container";
import Xeurix from "../assets/images/Xeurix.svg";
import HMZones from "../assets/images/HMZones.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
// import required modules
import { EffectCoverflow,Pagination,Autoplay } from "swiper";

const Colaborated = memo(() => {
  const Data = [
    {
      id: "d1",
      img: Xeurix,
      alt: "Xeurix",
    },
    {
      id: "d2",
      img: HMZones,
      alt: "HMZones",
    },
  ];
  return (
    <div className="my-10">
      <Container>
        <>
          <h1 className="font-Bagero text-[#274659] text-center md:text-left text-2xl sm:text-4xl md:text-5xl font-semibold py-6">
            Collaborated with
          </h1>
          <div className="flex flex-col md:flex-row my-4 lg:my-0 text-center items-center md:text-left">
            <ColabL />
            <div className=" basis-[20%] ">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination,Autoplay]}
                className="mySwiper"
              >
                {Data.map((obj) => (
                  <SwiperSlide key={obj.id} className="m-2">
                    <ColabR img={obj.img} alt={obj.alt} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </>
      </Container>
    </div>
  );
});

export default Colaborated;
