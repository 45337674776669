import React, { memo } from "react";

const Privacy = memo(() => {
  return (
    <div className="m-2 text-sm">
      <h2 className="text-center font-medium">GameBite. Privacy Policy</h2>
      <h2 className="text-center py-2 font-medium">Updated on : 21 Sept, 2022</h2>
      <p>
        We, GameBite regards your information significant and release this
        Privacy Statement to inform you what information we collect and how we
        use it to personalise and continually improve you experience.
      </p>
      <p className="pt-2">
        This Privacy Statement applies to GameBite website and its services
        which collect data from you and does not apply to the product or service
        that which does not link to this statement or has its privacy statement
        respectively.
      </p>
      <h2 className="pt-2 font-medium">Information We Collect</h2>
      <ol type="a">
        <li>
          a. Personal Information. We do not collect Personal Information.
          Personal Information is information that identifies you or another
          person, such as your first name and last name, physical addresses,
          email addresses, telephone, fax, SSN, information stored within your
          device.
        </li>
        <li>
          b. Non-personal Information. We collect your non-personal information
          when you visit our website or using our mobile service, including your
          device information, operation system, access log. For example, we may
          use to obtain the above information when you accesses our mobile app
          services.
        </li>
        <li>
          c. Information you provide. We may collect your information when you
          communicate with us or you give us.
        </li>
      </ol>
      <h2 className="pt-2 font-medium">How We Use Information</h2>
      <ol type="a">
        <li>
          a.Personal Information. We do not store Personal Information and
          therefore we do not disclose your Personal Information.
        </li>
        <li>
          b. Non-Personal Information. We do not sell, trade, or otherwise
          transfer to outside parties your information. We do not combine
          Non-Personal Information with Personal Information (such as combining
          your name with your unique User Device number). We use Non-personal
          information only for our cross promotion. Relevant data will not be
          shared with other parties.
        </li>
        <li>c. Legal Reasons</li>
      </ol>
      <p>
        We will access, use or disclose your information with other
        organisations or entities for reasons that required by any applicable
        law, regulation, legal process or enforceable governmental request;
        detect, prevent, or otherwise fraud, security or technical issues;
        protect against harm to the rights, property or safety GameBite, our
        users or the public as required or permitted by law.
      </p>
      <h2 className="py-2 font-medium">Data Controller and Data Processor</h2>
      <p>
        In accordance with GDPR, we set our duty on data controller and data
        processor. Data controllers decide the "purposes" and "means" of any
        processing of personal data. Similar to what's already in place for data
        protection law today, data controllers will have to adopt compliance
        measures to cover how data is collected, what it is being used for, how
        long it is being retained for and ensure that people have a right to
        access the data held about them. Data processors handle personal data on
        behalf of a data controller. Certain obligations now apply directly to
        data processors, and controllers must bind them to certain contractual
        commitments to ensure that data is processed safely and legally.
      </p>
      <h2 className="pt-2 font-medium">Security</h2>
      <p>
        GameBite is very concerned about safeguarding the confidentiality of
        your information. We do not collect Personal Information, and we employ
        administrative, physical and electronic measures designed to protect
        your Non-Personal Information from un-authorised access and use. Please
        be aware that no security measures that we take to protect your
        information is absolutely guaranteed to avoid un-authorised access or
        use of your Non-Personal Information which is impenetrable.
      </p>
      <h2 className="py-2 font-medium">Dispose Non-personal information</h2>
      <p>
        The non-personal information is automatically destroyed when you do not
        access our website or our services for 6 months. Once the data is
        disposed, it cannot be restored.
      </p>
      <h2 className="pt-2 font-medium">Sensitive Information</h2>
      <p>
        We ask that you not send us, and you not disclose, any sensitive
        Personal Information (e.g., information related to racial or ethnic
        origin, political opinions, religion or other beliefs, health, sexual
        orientation, criminal background or membership in past organisations,
        including trade union memberships) on or through an Application, the
        Services or the Site or otherwise to us.
      </p>
      <h2 className="pt-2 font-medium">Children</h2>
      <p>
        We do not provide service focus on Children. If you are under 18, you
        may use our website when you are with a parent or guardian.
      </p>
      <h2 className="pt-2 font-medium">Contact Us</h2>
      <p>
        If you have any questions or comments about this Policy or our privacy
        practices, or to report any violations of the Policy or abuse of the
        Services or the Site, please contact us at zehrakanis@gmail.com
      </p>
      <h2 className="pt-2 font-medium">Changes</h2>
      <p>
        Our Privacy Policy may change which will not reduce your rights under
        this Privacy Policy from time to time, we will post any privacy policy
        changes on this page, so please review it periodically. If you do not
        agree to any modifications to this Policy, your could immediately stop
        all use of all the Services. Your continued use of the Site following
        the posting of any modifications to this Policy will constitute your
        acceptance of the revised Policy. Please note that none of our employees
        or agents has the authority to vary any of our Policies.
      </p>
    </div>
  );
});

export default Privacy;
