import React from "react";
import FormSection from "../components/FormSection";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const Contact = () => {
  return (
    <div>
      <Navbar defaultP={true} />
      <FormSection />
      <Footer />
    </div>
  );
};

export default Contact;
