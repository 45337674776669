import React, { memo } from "react";
import CompaniesCard from "./utilities/CompaniesCard";
import Container from "./utilities/Container";
import { useContext, useEffect } from "react";
import { FirebaseContext } from "../context/Firebase";
import { CompaniesProps } from "../Datatypes/main";
const WhyCompanies = memo(() => {
    const { CompaniesData , getCompaniesData} = useContext(FirebaseContext);
    useEffect(() => {
      getCompaniesData();
    },[]);
  return (
    <div className=" my-10">
      <Container>
        <>
          <h1 className="font-Bagero text-[#274659] text-center md:text-left text-2xl sm:text-4xl md:text-5xl font-semibold py-6">
            Why Companies uses to Choose Game Bite
          </h1>
          <div className="flex flex-wrap justify-center items-center">
            {(CompaniesData || []).map((obj: CompaniesProps) => (
              <CompaniesCard key={obj.id} data={obj} />
            ))}
          </div>
        </>
      </Container>
    </div>
  );
});

export default WhyCompanies;
