import React, { memo } from "react";
import { BsGooglePlay } from "react-icons/bs";
import { ImLinkedin } from "react-icons/im";
import { AiFillYoutube } from "react-icons/ai";
import { RiWhatsappFill } from "react-icons/ri";
import { GoDeviceMobile } from "react-icons/go";
import { MdLocationPin } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import tiktok from "../../assets/images/Tk.png"
import mail from "../../assets/images/mail.png"
const ContactMe = memo(() => {
  return (
    <div className="order-3 sm:order-2 md:order-3 text-white">
      <h2 className=" font-Bagero my-3 pt-3 text-lg font-normal opacity-80">
        Contact me
      </h2>
      <div className=" space-y-7 text-xl font-normal">
        <div className="flex pt-4">
          <span className="mr-3">
            <FaUserAlt className=" w-6 h-6 opacity-75" />
          </span>
          <h3 className="opacity-60">Mazher</h3>
        </div>
        <div className="flex ">
          <span className="mr-3">
            <img src={mail} className=" w-6 h-6" />
          </span>
          <h3 className="opacity-60">zehrakanis@gmail.com</h3>
        </div>
        <div className="flex ">
          <span className="mr-3">
            <GoDeviceMobile className=" w-6 h-6 opacity-75" />
          </span>
          <h3 className="opacity-60">+27 68 679 9892</h3>
        </div>
        <div className="flex">
          <span className="mr-3">
            <MdLocationPin className="w-6 h-6 opacity-75" />
          </span>
          <h3 className="opacity-60">Musgrave, Durban, South Africa</h3>
        </div>
        <div className="flex space-x-4 pt-5">
          <img src={tiktok} className="h-7 w-7 " />
          <BsGooglePlay className="h-7 w-7 text-white opacity-90" />
          <ImLinkedin className="h-7 w-7 text-white opacity-90" />
          <RiWhatsappFill className="h-7 w-7 text-white opacity-90" />
          <AiFillYoutube className="h-7 w-7 text-white opacity-90" />
        </div>
      </div>
    </div>
  );
});

export default ContactMe;
