import React, { FC,memo } from 'react';
const SwipeCard:FC<{head:string,img:string,text:string,detail:string}> = memo(({img,text,head,detail}) => {
    return (
        <div className='md:my-24 flex justify-center'>
            <div className=' bg-[#2976A6] flex flex-col md:flex-row items-start md:items-center text-white min-h-60 w-[780px]'>
                <div className=' basis-1/2 flex:col sm:flex md:flex-col mx-auto sm:mx-0 text-center relative md:bottom-14'>
                    <div className=' h-56 w-56 rounded-full  m-2 md:m-0 md:mx-auto overflow-hidden border-[6px] border-[#ccdcea] '>
                        <img src={img} alt="" className=' w-full'/>
                    </div>
                    <div className=' my-auto'>
                        <h1 className='font-Bagero py-3 text-2xl font-normal'>{head}</h1>
                        <p className='font-Bagero text-lg font-normal px-5'>{text}</p>
                    </div>
                </div>
                <div className=' basis-1/2'>
                    <p className=' text-2xl font-normal p-4 text-center sm:text-left'>{detail}</p>
                </div>
            </div>
        </div>
    );
});

export default SwipeCard;