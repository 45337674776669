import { FC, memo } from "react";
import { whyChoseProps } from "../../Datatypes/main";

const MakeCard:FC<{data:whyChoseProps,indexVal:number}> = memo(({data,indexVal}) => {
    return (
        <div className={`w-full sm:w-96 min-h-72 bg-[#328BD9] mx-auto text-white p-6 my-3 relative top-[2px] [${indexVal}px]`}>
            <h1 className='font-Bagero text-3xl font-medium text-center'>{data?.head}</h1>
            <h2 className='font-Bagero text-md font-medium text-center py-5'>{data?.text}</h2>
            <p className=' text-lg font-normal text-center'>{data?.des}</p>
        </div>
    );
});

export default MakeCard;