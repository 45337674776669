import React, { memo } from 'react';
import Container from './utilities/Container';
import About from "../assets/images/About 1.png";
import Object from "../assets/images/Object.png";
import tech from "../assets/images/tech.png";
import ServiceBox from './utilities/ServiceBox';
const Services = memo(() => {
    const ServicesData=[
        {
            id:Math.random(),
            title:"About Us",
            desc:" Welcome to Game Bite, the ultimate community for game developers and enthusiasts! We provide a platform where developers of all skill levels can connect, collaborate, and create amazing games together. Our community is filled with friendly and knowledgeable members who are always willing to lend a helping hand and share their expertise. From tutorials and articles to reviews of the latest games and engines, we've got you covered. Join us today and start exploring the exciting world of game development!",
            img:About,
            direction:true
        },
        {
            id:Math.random(),
            title:"Our Services",
            desc:"  we're here to help game developers create amazing games. Our services include tutorials, community support, game reviews, a resource library, and game development consulting. Whether you're a beginner or an experienced developer, we provide the tools and resources you need to succeed in game development. Our tutorials cover a wide range of game development topics, while our community is always ready to provide support whenever you need it.",
            img:Object,
            direction:false
        },
        {
            id:Math.random(),
            title:"Our Tech",
            desc:"We provide teaching resources for game development, including tutorials, courses, workshops, and community support. Our resources cater to all skill levels and cover a wide range of topics. Our workshops provide hands-on experience, and our community is always ready to offer support and advice. Join us to start creating amazing games today.They can also teach life skills such as problem-solving, teamwork, and communication. Additionally, games can be customized to meet individual needs and are a fun way to learn!",
            img:tech,
            direction:true
        }
    ]
    return (
        <div className='py-10' id='Services'>
            <Container>
                <>
                    <div>
                        {
                            ServicesData.map((obj:any)=><ServiceBox key={obj.id} data={obj}/>)
                        }
                    </div>
                </>
            </Container>
        </div>
    );
});
export default Services;