import React, { memo, useState, useContext } from "react";
import { FirebaseContext } from "../../context/Firebase";
const FormL = memo(() => {
  const { SaveInfo } = useContext(FirebaseContext);
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
    option: "",
    date: new Date().toString(),
  });
  const saveformData = async (e: any) => {
    e.preventDefault();
    const response: any = await SaveInfo(data);

    if (response) {
      setData({
        name: "",
        email: "",
        message: "",
        option: "",
        date: new Date().toString(),
      });
    }
  };
  return (
    <div className=" basis-1/2 order-2 md:order-1 lg:basis-2/5">
      <form
        onSubmit={saveformData}
        className=" text-white text-2xl font-medium"
      >
        <input
          className=" placeholder-white mb-5 bg-transparent shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
          id="username"
          required
          autoComplete="off"
          type="text"
          placeholder="Name"
          value={data.name}
          onChange={(event) => {
            setData({ ...data, name: event.target.value });
          }}
        />
        <input
          className="placeholder-white my-5 bg-transparent shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
          id="username"
          autoComplete="off"
          required
          type="email"
          placeholder="Email"
          value={data.email}
          onChange={(event) => {
            setData({ ...data, email: event.target.value });
          }}
        />
        <select
          className="my-5 bg-transparent shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
          value={data.option}
          required
          onChange={(event) => {
            setData({ ...data, option: event.target.value });
          }}
        >
          <option className=" bg-neutral-500 bg-opacity-30">Demo</option>
          <option className=" bg-neutral-500 bg-opacity-30">Meeting</option>
          <option className=" bg-neutral-500 bg-opacity-30">Job</option>
        </select>
        <textarea
          className="placeholder-white my-5 bg-transparent shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Description"
          value={data.message}
          required
          onChange={(event) => {
            setData({ ...data, message: event.target.value });
          }}
        />
        <button
          type="submit"
          className="font-Bagero my-12 hover:bg-opacity-20 hover:bg-white  text-xl font-medium py-3 px-12 border border-gray-400 rounded shadow"
        >
          Submit
        </button>
      </form>
    </div>
  );
});
export default FormL;
