import React, { memo } from 'react';
import Container from './utilities/Container';
import jorge from "../assets/images/jorge-de-castro-icon-b-01 1.jpg";
import {GrYoutube} from 'react-icons/gr';
import {BsGooglePlay} from 'react-icons/bs';
import {FaAppStoreIos} from 'react-icons/fa';
import char from "../assets/images/Character.svg";
const CreateGame = memo(() => {
    return (
        <div className='my-10 flex '>
            <Container>
                <>
                    <div className=''>
                        <h1 className='font-Bagero text-[#274659] text-center md:text-left text-2xl sm:text-4xl md:text-5xl font-semibold py-6'>Create Games</h1>
                    </div>
                    <div className='flex flex-col items-center'>
                        <div className=' w-full lg:h-[365px] bg-[#2976A6] flex flex-col lg:flex-row items-center'>
                            <div className='w-full lg:w-3/4'>
                                <div className='px-2 md:px-7 my-2 flex'>
                                    <div>
                                        <img src={jorge} alt="" />
                                    </div>
                                    <div className='text-white p-3'>
                                        <h2 className='font-Bagero text-lg sm:text-2xl font-medium'>Element Defence</h2>
                                        <h3 className='font-Bagero text-lg opacity-70 font-normal'>Game Type</h3>
                                    </div>
                                </div>
                                <div className='sm:w-3/4 mx-2 sm:mx-16'>
                                    <div className='py-5'><p className=' text-base font-normal text-white'>Our company develops prototypes and games in collaboration with the following partners.Our company develops prototypes and games in collaboration with the following partners.</p></div>
                                    <div className=' sm:w-3/4 flex justify-between items-center'>
                                        <button className="font-Bagero bg-white hover:bg-gray-100 text-[#2A7AAB] text-xl font-normal py-2 px-4 border border-gray-400 rounded shadow">
                                            <a href="#games">Visit Games</a> 
                                        </button>
                                        <GrYoutube className=' h-10 w-10 text-white'/>
                                        <BsGooglePlay className=' h-10 w-10 text-white'/>
                                        <FaAppStoreIos className=' h-10 w-10 text-white'/>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src={char} alt=""/>
                            </div>
                        </div>
                        {/* <div className='pt-7 text-center w-3/4'>
                            <button className="hover:bg-opacity-20 hover:bg-white text-2xl font-medium text-[#2976A6] bg-transparent py-2 px-12 border-4 border-[#2976A6] rounded">
                                <a href='#provide'>See More</a>
                            </button>
                        </div> */}
                    </div>
                </>
            </Container>
        </div>
    );
});

export default CreateGame;