import React, {memo } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
// import required modules
import { EffectCoverflow, Pagination } from "swiper";
import Container from "./utilities/Container";
import SwipeCard from "./utilities/SwipeCard";

import { useContext, useEffect } from "react";
import { FirebaseContext } from "../context/Firebase";
import { clientProps } from "../Datatypes/main";
const Client = memo(() => {

  const { clientData , getClientData} = useContext(FirebaseContext);
  useEffect(() => {
    getClientData();
  },[]);
  return (
<Container className="my-10">
        <>
        <h1 className='font-Bagero text-[#274659] text-center md:text-left text-2xl sm:text-4xl md:text-5xl font-semibold py-6'>What Clients Saying</h1>
        <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
        >
        {
          (clientData||[]).map((obj:clientProps)=> (
            <SwiperSlide key={obj.id}>
              <SwipeCard  head={obj.head} detail={obj.detail} img={obj.img} text={obj.text} />
            </SwiperSlide>
          ))
        }
        </Swiper>
        </>
    </Container>
  );
});

export default Client;