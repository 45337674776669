import React, { memo } from 'react';

const ColabL = memo(() => {
    return (
        <div>
            <h3 className=' text-4xl font-medium text-[#2976A6] basis-1/4 leading-[50px] pt-2'>Our company develops prototypes and games in collaboration with the following partners.</h3>
        </div>
    );
});

export default ColabL;