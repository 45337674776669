import React, { memo } from 'react';
import Container from './utilities/Container';
import FooterL from './utilities/FooterL';
import FooterR from './utilities/FooterR';
import ContactMe from './utilities/ContactMe';

const Footer = memo(() => {
    return (
        <div id='customerSuport' className='bg-[#274659] py-16'>
            <Container>
                <>
                    <div className='flex flex-col sm:flex-row sm:flex-wrap justify-between'>
                        <FooterL/>
                        <FooterR/>
                        <ContactMe/>
                    </div>
                </>
            </Container>
        </div>
    );
});

export default Footer;