import React, { memo } from 'react';

const FormR = memo(() => {
    return (
        <div className=' basis-2/5 py-16 order-1 md:order-2 text-white text-center md:text-left'>
            <h1 className='font-Bagero mb-5 text-3xl md:text-4xl lg:text-5xl font-semibold'>Lets Create A Game</h1>
            <p className='my-5 text-2xl lg:text-3xl font-medium'>Feel free to share with us your questions, suggestions, or ideas in the form.</p>
            <button className="hidden font-Bagero my-12 hover:bg-opacity-20 hover:bg-white text-xl font-medium py-3 px-12 border border-gray-400 rounded shadow">
                Submit
            </button>
        </div>
    );
});

export default FormR;