import React, { memo } from "react";
import Container from "./utilities/Container";
import GamesCard from "./utilities/GamesCard";
import { GamesProps } from "../Datatypes/main";
import mainData from '../data/maindata.json'
const MadeGames = memo(() => {
    const ChoseData:GamesProps[]=mainData.MadeGamesData
  return (
    <div className=" my-10" id="games">
      <Container>
        <>
          <h1 className=" font-Bagero text-[#274659] text-center md:text-left text-2xl sm:text-4xl md:text-5xl font-semibold py-6">
            Our Made Games
          </h1>
          <div className=" flex flex-wrap justify-center md:justify-between">
            {ChoseData.map((obj: GamesProps) => (
              <GamesCard key={obj.id} data={obj} />
            ))}
          </div>
        </>
      </Container>
    </div>
  );
});

export default MadeGames;
