import { createContext, useContext, useReducer } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, getDocs, collection,addDoc } from "firebase/firestore/lite";
import StoreReducer from "./StoreReducer";
import actions from "./Actions";
import { initialFirebaseContext } from "../Datatypes/main";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import Swal from "sweetalert2";
const { GET_Client_DATA, GET_Companies_DATA, GET_Info_DATA,SAVE_IN_INFO,SIGN_IN_INFO } = actions;
//firebase project link
const firebaseConfig = {
  apiKey: "AIzaSyDKYY3uz8zNmOhkE-GVwKa0iyTGJsYxKxI",
  authDomain: "gamebite-78c70.firebaseapp.com",
  projectId: "gamebite-78c70",
  storageBucket: "gamebite-78c70.appspot.com",
  messagingSenderId: "668330649893",
  appId: "1:668330649893:web:8974bff2b922cc268592cc",
  measurementId: "G-E582QH09K2",
};
const firebaseApp = initializeApp(firebaseConfig);
export const useFirebase = () => useContext(FirebaseContext);

const initialContext: initialFirebaseContext = {
  clientData: [],
  CompaniesData: [],
  InfoData: [],
  userInfo: JSON.parse(localStorage.getItem("userInfo")||'null'),
  getClientData: () => {},
  getCompaniesData: () => {},
  getInfoData: () => {},
  signinUser: () => {},
  SaveInfo: (data:any) => {},
  logoutUser: () => {},
};

export const FirebaseContext = createContext(initialContext);
export const FirebaseProvider = (props: any) => {
  const [state, dispatch] = useReducer(StoreReducer, initialContext);

  //Save Info Data
  const SaveInfo = (data: any) => {
    const userCollectionRef = collection(db, "contactdata");
    return addDoc(userCollectionRef, data)
      .then((value) => {
        dispatch({ payload: value, type: SAVE_IN_INFO });
        Swal.fire("Good job!", "Your data has been submitted!", "success");
        return true;
      })
      .catch((error) => {
        alert(error?.message);
        return false;
      });
  };
  //Clients Reviw data
  const getClientData = async () => {
    const clientdata = await getDocs(
      collection(getFirestore(firebaseApp), "clientreview")
    );
    const clientnewData: any = [];
    clientdata.forEach((obj) => clientnewData.push(obj.data()));

    dispatch({
      payload: clientnewData,
      type: GET_Client_DATA,
    });
  };
  //Companies Data
  const getCompaniesData = async () => {
    const CompaniesData = await getDocs(
      collection(getFirestore(firebaseApp), "whyCompanies")
    );
    const CompaniesnewData: any = [];
    CompaniesData.forEach((obj) => CompaniesnewData.push(obj.data()));

    dispatch({
      payload: CompaniesnewData,
      type: GET_Companies_DATA,
    });
  };
 //Client Info Data
 const getInfoData = async () => {
  const Infodata = await getDocs(
    collection(getFirestore(firebaseApp), "contactdata")
  );
  const InfonewData: any = [];
  Infodata.forEach((obj) => InfonewData.push(obj.data()));
  dispatch({
    payload: InfonewData,
    type: GET_Info_DATA,
  });
};
 //Log out user
 const logoutUser = () => {
  signOut(auth);
  dispatch({ payload: null, type: SIGN_IN_INFO });
  localStorage.setItem('userInfo',JSON.stringify(""))
};
//Sign In User
const signinUser = (email: string, password: string) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((value) => {
      dispatch({ payload: value, type: SIGN_IN_INFO });
      localStorage.setItem('userInfo',JSON.stringify(value))
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Valid Email",
        showConfirmButton: false,
        timer: 1000,
      });
    })
    .catch((err) => {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "In-Valid Email",
        showConfirmButton: true,
      });
    });
};
  return (
    <FirebaseContext.Provider
      value={{ ...state, getClientData, getCompaniesData, getInfoData,SaveInfo,signinUser,logoutUser }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
};
//previous
export const db = getFirestore(firebaseApp);
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
