import { FC, memo } from "react";
import { CompaniesProps } from "../../Datatypes/main";
const CompaniesCard:FC<{data:CompaniesProps}> = memo(({data}) => {
    return (
        <div className='border-b-2 border-r-0 lg:border-r-2 border-[#2976A6] border-opacity-20 even:border-r-0 last:border-0 last:border-b-0 xl:[&:nth-child(3)]:border-r-0 xl:[&:nth-child(4)]:border-b-0 lg:[&:nth-child(5)]:border-b-0 xl:[&:nth-child(2)]:border-r-2 xl:[&:nth-child(4)]:border-r-2 text-center'>
            <div className='py-10 lg:py-5 w-full lg:w-[370px] lg:min-h-[390px] bg-[#CCDCEA] hover:z-[50] relative hover:shadow-2xl'>
                <div className=' py-2 flex flex-col sm:flex-row justify-center items-center text-[#2976A6]'>
                    <img src={data?.img} alt="" className=" w-14"/>
                    <h1 className='font-Bagero pl-3 py-2 text-lg sm:text-xl font-medium'>{data?.head}</h1>
                </div>
                <p className='p-3  text-xl font-normal text-[#0068C2]'>{data?.detail}</p>
            </div>
        </div>
    );
});

export default CompaniesCard;