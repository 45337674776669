import React, { memo } from 'react';
import Container from './utilities/Container';
import FormL from './utilities/FormL';
import FormR from './utilities/FormR';

const FormSection = memo(() => {
    return (
        <div className=' min-h-screen bg-[url("../images/bg.png")] bg-no-repeat bg-cover flex items-center'  id="contact">
            <Container>
                <>
                    <div className=' flex flex-col md:flex-row md:justify-between'>
                        <FormL/>
                        <FormR/>
                    </div>
                </>
            </Container>
        </div>
    );
});

export default FormSection;